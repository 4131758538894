<script setup lang="ts">
import { cookiesAccess } from '@/shared/lib/cookiesAccess'
import { ButtonSecondary, ButtonPrimary } from '@/shared/ui/button'
import { ModalDefault, ModalCloseWhite } from '@/shared/ui/modal'

import CookiesCard from './CookiesCard.vue'

const $modal = useModal()
const { $analytics } = useNuxtApp()

const state = reactive({
  open: false,
  necessary: true,
  functional: true,
  analytics: true,
  perfomance: true,
})

onMounted(() => {
  $modal.onOpen('cookies-access-modal', () => (state.open = true))
})

onBeforeUnmount(() => {
  $modal.off('cookies-access-modal', 'open')
})

function onClose() {
  state.open = false
}

function onRejectAll() {
  cookiesAccess.rejectAll()
  $modal.close('cookies-access-tollbar')
  onClose()
}

function onConfirmMyChoice() {
  cookiesAccess.custom(state.functional, state.analytics, state.perfomance)
  $analytics.init()
  $modal.close('cookies-access-tollbar')
  onClose()
}

function onConfirmAll() {
  cookiesAccess.acceptAll()
  $analytics.init()
  $modal.close('cookies-access-tollbar')
  onClose()
}
</script>

<template>
  <ModalDefault
    v-model="state.open"
    background="lg:mx-auto rounded-12 lg:min-w-600 bg-white"
    data-test="modal_cookies_accesss"
  >
    <template #header>
      <div class="text-right">
        <ModalCloseWhite data-test="modal_cookies_accesss-button_close" @click="onClose()" />
      </div>
    </template>

    <div class="text-h5-semibold mb-12">
      {{ $t('modals.cookies_access_modal.title') }}
    </div>
    <div class="text-h7-regular mb-24">
      {{ $t('modals.cookies_access_modal.subtitle') }}
    </div>
    <div class="bg-black-300 h-[1px] w-full" />
    <CookiesCard
      v-model="state.necessary"
      :title="$t('modals.cookies_access_modal.options_one_title')"
      :text="$t('modals.cookies_access_modal.options_one_description')"
      :always="true"
    />
    <div class="bg-black-300 h-[1px] w-full" />
    <CookiesCard
      v-model="state.functional"
      :title="$t('modals.cookies_access_modal.options_two_title')"
      :text="$t('modals.cookies_access_modal.options_two_description')"
    />
    <div class="bg-black-300 h-[1px] w-full" />
    <CookiesCard
      v-model="state.analytics"
      :title="$t('modals.cookies_access_modal.options_three_title')"
      :text="$t('modals.cookies_access_modal.options_three_description')"
    />
    <div class="bg-black-300 h-[1px] w-full" />
    <CookiesCard
      v-model="state.perfomance"
      :title="$t('modals.cookies_access_modal.options_fourth_title')"
      :text="$t('modals.cookies_access_modal.options_fourth_description')"
    />
    <div class="bg-black-300 h-[1px] w-full" />

    <template #footer>
      <div class="mt-24 flex flex-col items-center justify-between lg:flex-row">
        <ButtonSecondary
          class="mb-12 w-full px-16 lg:mb-0 lg:w-auto"
          size="50"
          data-test="modal_cookies_accesss-button_reject_all"
          @click="onRejectAll()"
        >
          {{ $t('modals.cookies_access_modal.button_reject_all') }}
        </ButtonSecondary>
        <div class="w-full lg:flex lg:w-auto">
          <ButtonSecondary
            class="mb-12 w-full whitespace-nowrap px-16 lg:mb-0 lg:mr-12 lg:w-auto"
            size="50"
            data-test="modal_cookies_accesss-button_confirm_choice"
            @click="onConfirmMyChoice()"
          >
            {{ $t('modals.cookies_access_modal.button_confirm_choice') }}
          </ButtonSecondary>
          <ButtonPrimary
            class="mb-12 w-full whitespace-nowrap px-16 lg:mb-0 lg:w-auto"
            size="50"
            data-test="modal_cookies_accesss-button_confirm_all"
            @click="onConfirmAll()"
          >
            {{ $t('modals.cookies_access_modal.button_confirm_all') }}
          </ButtonPrimary>
        </div>
      </div>
    </template>
  </ModalDefault>
</template>
