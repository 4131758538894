<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})

const model = defineModel({ type: Boolean, default: false })
</script>

<template>
  <div class="flex items-center">
    <slot />
    <label class="relative cursor-pointer">
      <input v-model="model" v-bind="$attrs" type="checkbox" class="peer sr-only" />
      <div
        class="peer-checked:bg-leaf peer flex h-[19px] w-32 items-center rounded-full bg-gray-300 after:absolute after:left-2 after:h-[14px] after:w-[14px] after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all peer-checked:after:translate-x-[94%] peer-checked:after:border-white"
      ></div>
    </label>
  </div>
</template>
