<script setup lang="ts">
import { FormToggle } from '@/shared/ui/form'

defineProps({
  title: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  always: {
    type: Boolean,
    default: false,
  },
})

const model = defineModel({ type: Boolean, default: false })
</script>

<template>
  <div class="mt-12">
    <div class="mb-8 flex items-center justify-between">
      <div class="text-h6-semibold">{{ title }}</div>
      <div v-if="always" class="text-h7-medium text-leaf">Always Active</div>
      <FormToggle v-else v-model="model" />
    </div>
    <div class="text-h7-regular mb-24">{{ text }}</div>
  </div>
</template>
